import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from "../styles/signup.module.css";

const SignIn = ({ setToken }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      const response = await axios.post("https://api-demo.bytheapp.com/register/signin", { email, password });
      setToken(response.data.token);
      localStorage.setItem("token", response.data.token);
      navigate("/dashboard");
    } catch (error) {
      setMessage("Invalid credentials");
    }
  };

  const handleResetRequest = async () => {
    try {
      await axios.post("http://localhost:8000/request-password-reset", { email: resetEmail });
      setResetMessage("Reset link sent! Check your email.");
    } catch (error) {
      setResetMessage("Failed to send reset email");
    }
  };

  return (
    <div className="container">

      <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
              <a className="navbar-brand page-scroll" href="#page-top">
                By The App
              </a>{" "}
            </div>

            
          </div>
        </nav>

      <div className={styles["signup-container"]}>
        <div className={styles["signup-box"]}>
          {showForgotPassword ? (
            <>
              <h2>Reset Password</h2>
              <input 
                type="email" 
                placeholder="Enter your email" 
                onChange={(e) => setResetEmail(e.target.value)} 
              />
              <button onClick={handleResetRequest}>Send Reset Link</button>
              <p className={styles.message}>{resetMessage}</p>
              <button 
                className={styles["back-button"]} 
                onClick={() => setShowForgotPassword(false)}
              >
                Back to Sign In
              </button>
            </>
          ) : (
            <>
              <h2>Sign In</h2>
              <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
              <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
              <button onClick={handleSignIn}>Sign In</button>
              <p className={styles.message}>{message}</p>
              <p 
                className={styles["forgot-password"]} 
                onClick={() => setShowForgotPassword(true)}
              >
                Forgot Password?
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
