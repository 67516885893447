import React, { useState } from "react";
import axios from "axios";
import styles from "../styles/signup.module.css";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSignUp = async () => {
    try {
      const response = await axios.post("https://api-demo.bytheapp.com/register/signup", { email, password });
      setMessage(response.data.message);
    } catch (error) {
      setMessage("Error signing up");
    }
  };

  return (
    
    <div className="contianer">
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top">
              By The App
            </a>{" "}
          </div>

          
        </div>
      </nav>
      <div className={styles["signup-container"]}>
          <div className={styles["signup-box"]}>
               <h2>Sign Up</h2>
               <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                <button onClick={handleSignUp}>Sign Up</button>
                <p className={styles.message}>{message}</p>
          </div>
          
       </div>
      
    </div>
  );
};

export default SignUp;
