import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from "../styles/dashboard.module.css";

const Dashboard = ({ token, setToken }) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);  // Track uploaded files and their statuses
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUpload = async () => {
    if (!file) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      // Upload file
      const response = await axios.post("http://localhost:8000/upload", formData, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
      });

      // Add the uploaded file and its status to the state
      setUploadedFiles([
        ...uploadedFiles,
        {
          fileName: file.name,
          status: "Uploaded",
          modelId: null,
        },
      ]);
      setMessage(response.data.message);
    } catch (error) {
      setMessage("Upload failed");
    } finally {
      setLoading(false);
    }
  };

  // Function to trigger fine-tuning via API
  const handleFineTune = async (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles[index].status = "Fine-Tuning...";  // Change status to 'Fine-Tuning'
    setUploadedFiles(updatedFiles);

    try {
      // Call your API to start fine-tuning
      const response = await axios.post(
        "http://localhost:8000/fine-tune", // Endpoint for fine-tuning
        { fileName: uploadedFiles[index].fileName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Assume the response contains modelId after fine-tuning
      updatedFiles[index].status = "Trained";  // Change status to 'Trained'
      updatedFiles[index].modelId = response.data.modelId; // Update modelId with response from API
      setUploadedFiles(updatedFiles);
    } catch (error) {
      updatedFiles[index].status = "Error in Fine-Tuning";
      setUploadedFiles(updatedFiles);
      setMessage("Error in Fine-Tuning");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
    navigate("/signin");
  };

  return (
    <div className={styles["container"]}>
      <nav className={styles["navbar"]}>
        <div className={styles["nav-content"]}>
          <a className={styles["brand"]} href="#">By The App</a>
          <button className={styles["logout-button"]} onClick={handleLogout}>Logout</button>
        </div>
      </nav>

      <div className={styles["dashboard-container"]}>
        <div className={styles["dashboard-box"]}>
          <h2>Dashboard</h2>
          <form className={styles["form"]}>
            <label className={styles["file-upload"]}>
              <input 
                type="file" 
                accept=".csv" 
                onChange={(e) => setFile(e.target.files[0])} 
              />
              <span>{file ? file.name : "Click to upload a CSV file"}</span>
            </label>
            {file && <p className={styles["file-name"]}>{file.name}</p>}
            <button 
              type="button" 
              className={styles["upload-button"]} 
              onClick={handleUpload} 
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload"}
            </button>
            <p className={styles["upload-message"]}>{message}</p>
          </form>

          {/* Table to display uploaded files */}
          <table className={styles["file-table"]}>
            <thead>
              <tr>
                <th>File Name</th>
                <th>Status</th>
                <th>Model ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {uploadedFiles.map((file, index) => (
                <tr key={index}>
                  <td>{file.fileName}</td>
                  <td>{file.status}</td>
                  <td>{file.modelId || "N/A"}</td>
                  <td>
                    {file.status === "Uploaded" && (
                      <button 
                        className={styles["fine-tune-button"]} 
                        onClick={() => handleFineTune(index)}
                      >
                        Fine-Tune
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
