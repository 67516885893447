import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "../styles/signup.module.css";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleReset = async () => {
    try {
      await axios.post("http://localhost:8000/reset-password", { token, new_password: newPassword });
      setMessage("Password reset successful. You can now sign in.");
      setTimeout(() => navigate("/signin"), 3000);
    } catch (error) {
      setMessage("Failed to reset password");
    }
  };

  return (
    <div className={styles["signup-container"]}>
      <div className={styles["signup-box"]}>
        <h2>Reset Password</h2>
        <input type="password" placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} />
        <button onClick={handleReset}>Submit</button>
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
};

export default ResetPassword;
